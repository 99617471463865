/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, pathname, serviceType, jsonLdData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const ogImage = site.siteMetadata.image
  const siteUrl = site.siteMetadata.siteUrl
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Launch Lab",
    "url": siteUrl,
    "logo": `${siteUrl}/launch-lab-logo.svg`,
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Service",
      "areaServed": "AU",
      "availableLanguage": "English"
    },
    "sameAs": [
      "https://www.facebook.com/launchlabau/",
      "https://twitter.com/Go_LaunchLab",
      "https://linkedin.com/company/launch-lab-australia",
      "https://www.instagram.com/launchlab_au/"
    ]
  }

  // Define the Service schema (if serviceType prop is provided)
  const serviceSchema = serviceType ? {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": serviceType,
    "provider": {
      "@type": "Organization",
      "name": "Launch Lab",
      "url": siteUrl
    },
    "areaServed": {
      "@type": "Place",
      "name": "Sydney and Canberra Australia"
    },
    "description": description,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "AUD",
      "price": "Based on requirements",  // Update this with actual pricing if available
      "url": `${siteUrl}${pathname}`
    }
  } : null

  return (
    <>
      <>
        <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
          f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K8HC89T');</script>`,
          }}
        />
      </>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: ogImage,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        {/* Add JSON-LD here, if available */}
        {jsonLdData && (
          <script type="application/ld+json">
            {JSON.stringify(jsonLdData)}
          </script>
        )}

        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>

        {serviceType && (
          <script type="application/ld+json">
            {JSON.stringify(serviceSchema)}
          </script>
        )}
      </Helmet>
    </>
  )
}

Seo.defaultProps = {
  lang: `en-AU`,
  meta: [],
  description: ``,
  jsonLdData: null,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  jsonLdData: PropTypes.object,
  serviceType: PropTypes.string,
}

export default Seo
