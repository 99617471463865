import { Link } from "gatsby"
import React from "react"
import Logo from "../images/launch-lab-logo.svg"
import Twitter from "../components/svg/twitter"
import Fb from "../components/svg/fb"
import Linkedin from "../components/svg/linkedin"
import Insta from "../components/svg/insta"

const Footer = () => (
  <footer>
    <div className="container">
        <div className="row">
            <div className="footer-cols">
                <div className="col-logo">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="Launch Lab logo" />
                    </Link>
                    <div className="schema-style" itemScope itemType="http://schema.org/LocalBusiness">
                        <span itemProp="description"><a itemProp="url" href="https://launchlab.com.au" className="fs-a"><span itemProp="name">Launch Lab</span>
                        </a> is a web design &amp; web development company in Sydney &amp; Canberra. We develop websites and applications using AWS, Python and React. We now also offer Webflow development.</span>
                    </div>
                    <div className="footer-social">
                        <a href="https://twitter.com/Go_LaunchLab" aria-label="X, formerly Twitter" target="_blank" rel="noopener noreferrer">
                            <span className="visually-hidden">Visit our Twitter (opens in new tab)</span>
                            <Twitter />
                        </a>
                        <a href="https://www.facebook.com/launchlabau/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                            <span className="visually-hidden">Visit our Facebook page (opens in new tab)</span>
                            <Fb />
                        </a>
                        <a href="https://linkedin.com/company/launch-lab-australia" aria-label="Linkedin" target="_blank" rel="noopener noreferrer">
                            <span className="visually-hidden">Visit our Linkedin page (opens in new tab)</span>
                            <Linkedin />
                        </a>
                        <a href="https://www.instagram.com/launchlab_au/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                            <span className="visually-hidden">Visit our Instagram page (opens in new tab)</span>
                            <Insta />
                        </a>
                    </div>
                </div>
                <div className="col-footer">
                    <h6>Launch Lab</h6>
                    <Link to="/about/">About</Link>
                    <Link to="/services/">Services</Link>
                    <Link to="/portfolio/">Our work</Link>
                    <Link to="/contact/">Contact</Link>
                    <Link to="/blog/">Blog</Link>
                    <Link to="/react-jobs/">React Jobs</Link>
                </div>
                <div className="col-footer">
                    <h6>Services</h6>
                    <Link to="/web-design/">Web Designers</Link>
                    <Link to="/web-development/">Web Developers</Link>
                    <Link to="/startup-web-designers/">Startup Web Design</Link>
                    <Link to="/startup-web-application-development/">Startup Developers</Link>
                    <Link to="/aws-infrastructure/">AWS Developers</Link>
                    <Link to="/artificial-intelligence-developers/">AI Developer</Link>
                    <Link to="/machine-learning-developers-sydney/">Machine Learning</Link>
                </div>
                <div className="col-footer">
                    <h6>Tools</h6>
                    <Link to="/python-developer/">Python Developers</Link>
                    <Link to="/react-developers-sydney-canberra/">React Developers</Link>
                    <Link to="/gatsby-developer/">Gatsby Developers</Link>
                    <Link to="/headless-cms/">Headless CMS</Link>
                    <Link to="/webflow-developer/">Webflow Developers in Sydney</Link>
                    <Link to="/leveraging-llms-and-AI-tools-in-startups/">LLMs</Link>
                </div>
            </div>
        </div>
    </div>

    <div className="footer-bottom">
        <div className="container">
            <div className="row">
                <div className="copy">
                    © Launch Lab Pty Ltd. {new Date().getFullYear()}. All rights reserved. <Link to="/terms/" className="terms-footer">Terms &amp; Privacy</Link> <span role="img" aria-hidden="true" aria-label="Australian flag">🇦🇺</span>
                </div>
            </div>
        </div>
    </div>
  </footer>
)

export default Footer